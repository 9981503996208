import React, { useState } from "react";
import { PlayerInfo } from "@/lib/types";
import ItemIndicator from "@/components/Item/ItemIndicator";
import InventoryDisplay from "@/components/Inventory/InventoryDisplay";
import Inventory from "@/components/Inventory/Inventory";


export interface BrowseDisplayProps {
  data: PlayerInfo[];
}

const BrowseDisplay: React.FC<BrowseDisplayProps> = ({ data }) => {

  const [open, setOpen] = useState<Record<string, true>>({});
  const toggleOpen = (uuid: string) => {
    const { [uuid]: isOpen, ...rest } = open;
    setOpen(isOpen ? rest : { ...rest, [uuid]: true });
  };

  return <>
    {
      data.map(playerInfo => <React.Fragment key={playerInfo.fullUuid}>
        <div className="flex py-2 cursor-pointer" onClick={() => toggleOpen(playerInfo.fullUuid)}>
          <ItemIndicator open={open[playerInfo.fullUuid]} />
          <div className="flex-initial px-1">
            <img className="h-8 w-8" src={`https://crafatar.com/avatars/${playerInfo.uuid}?overlay&size=32`} />
          </div>
          <div className="flex-auto px-1">
            <b>{playerInfo.name}</b>
          </div>
        </div>
        {
          open[playerInfo.fullUuid] && (
            playerInfo.playerInventory !== undefined ? (
              <InventoryDisplay playerInventory={playerInfo.playerInventory} />
            ) : (
              <Inventory fullUuid={playerInfo.fullUuid} />
            )
          )
        }
      </React.Fragment>)
    }
  </>;
};

export default BrowseDisplay;
