import fetchJson from "@/lib/fetchJson";
import Link from "next/link";
import Router, { NextRouter, useRouter } from "next/router";

const classesSelected = "bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium";
const classesIdle = "text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium";

const getClasses = (router: NextRouter, path: string) => {
  if (router.pathname === path) {
    return classesSelected;
  } else {
    return classesIdle;
  }
};

const Navbar: React.FC = () => {

  const router = useRouter();

  const logout = () => {
    fetchJson("/api/logout").then(() => {
      Router.push('/signin');
    });
  };

  return (
    <nav className="bg-gray-800">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <div className="flex items-center">
            {/* <div className="flex-shrink-0 bg-gray-400 rounded-full ring-2 ring-gray-400 ring-offset-2 ring-offset-gray-400 mr-10">
              <img className="h-8 w-8" src="/icon.png" alt="Player X-Ray" />
            </div> */}
            <div className="hidden md:block">
              <div className="flex items-baseline space-x-4">
                {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                <Link href="/" className={getClasses(router, '/')}>
                  Browse
                </Link>
                <Link href="/search" className={getClasses(router, '/search')}>
                  Search
                </Link>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                <a href="#" onClick={logout} className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">Logout</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile menu, show/hide based on menu state. */}
      <div className="md:hidden" id="mobile-menu">
        <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
          {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
          <Link href="/" className={getClasses(router, '/')}>
            Browse
          </Link>
          <Link href="/search" className={getClasses(router, '/search')}>
            Search
          </Link>
          <a href="#" onClick={logout} className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Logout</a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
