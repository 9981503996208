import React from "react";

const itemIndicatorStyle = { marginTop: '0.5rem', marginLeft: '-1.125rem' };

const ItemIndicator: React.FC<{ open: boolean }> = ({ open }) => {

  let content: React.ReactNode;

  if (open) {
    content = (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
      </svg>
    );
  }
  else {
    content = (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
      </svg>
    );
  }

  return (
    <div className="absolute" style={itemIndicatorStyle}>
      {content}
    </div>
  );
};

export default ItemIndicator;
