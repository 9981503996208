import textures from "minecraft-textures/dist/textures/1.20";
import { Item } from "minecraft-textures";


const textureItems = textures.items.reduce<Record<string, Item>>((textureItems, item) => {
  textureItems[item.id] = item;
  return textureItems;
}, {});

export default textureItems;
