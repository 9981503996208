import React, { useState } from "react";
import { InventoryContainer, InventoryItem, ItemEnchantment } from "@/lib/types";
import ItemIndicator from "@/components/Item/ItemIndicator";
import textureItems from "@/lib/textureItems";
import ContainerDisplay from "@/components/Container/ContainerDisplay";


export interface ItemDisplayProps {
  item: InventoryItem;
}

const ItemDisplay: React.FC<ItemDisplayProps> = ({ item }) => {

  const hasEnchantments = item.enchantments !== null;
  const hasSubContent = item.subContent !== null && item.subContent.length > 0;
  const isExpandable = hasEnchantments || hasSubContent;

  const [enchantments, setEnchantments] = useState<ItemEnchantment[] | null>(null);

  const [subContent, setSubContent] = useState<InventoryContainer | null>(null);
  const [raw, setRaw] = useState<InventoryItem | null>(null);

  return <>
    <div
      className={`flex py-1${isExpandable ? '  cursor-pointer' : ''}`}
      onClick={
        hasSubContent
          ? (() => setSubContent(subContent === null ? item.subContent : null))
          : hasEnchantments
            ? (() => setEnchantments(enchantments === null ? item.enchantments : null))
            : undefined
      }
    >
      {
        isExpandable && (
          <ItemIndicator open={enchantments !== null || subContent !== null} />
        )
      }
      <div className="flex-initial">
        <img className="h-8 w-8" src={textureItems[item.id].texture} />
      </div>
      <div className="flex-initial ml-1 w-8 italic">
        {item.count > 1 && `${item.count}x`}
      </div>
      <div className="flex-auto inline-flex">
        {
          isExpandable ? (
            <b>{item.display !== null ? item.display : textureItems[item.id].readable}</b>
          ) : (
            item.display !== null ? item.display : textureItems[item.id].readable
          )
        }
        {
          item.rawItem !== null && (
            raw === null ? (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-1 cursor-pointer" onClick={(event) => {
                event.stopPropagation();
                setRaw(item.rawItem);
              }}>            
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-1 cursor-pointer" onClick={(event) => {
                event.stopPropagation();
                setRaw(null);
              }}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            )
          )
        }
      </div>
    </div>
    {
      enchantments !== null && (
        <div className="flex-initial ml-3 flex flex-col">
          {enchantments.map((enchantment) => (
            <div key={enchantment.id} className="flex py-1">
              <div className="flex-initial">
                <img className="h-8 w-8" src={textureItems['minecraft:enchanted_book'].texture} />
              </div>
              <div className="flex-auto ml-1 w-8 italic">
                {enchantment.display}
              </div>
            </div>
          ))}
        </div>
      )
    }
    {
      raw !== null && (
        <div className="flex-auto ml-3 rounded bg-gray-800 p-2">
          <pre className="text-xs whitespace-pre-wrap break-words">
            {JSON.stringify(raw, null, 2)}
          </pre>
        </div>
      )
    }
    {
      subContent !== null && (
        <div className="flex-initial ml-3">
          <ContainerDisplay content={subContent} />
        </div>
      )
    }
  </>;
};

export default ItemDisplay;
