import React from "react";
import { InventoryContainer } from "@/lib/types";
import ItemDisplay from "@/components/Item/ItemDisplay";


export interface ContainerDisplayProps {
  content: InventoryContainer;
}

const ContainerDisplay: React.FC<ContainerDisplayProps> = ({ content }) => {

  return (
    <div className="flex flex-col">
      {content.map((item, index) => <ItemDisplay key={item.slot ?? index} item={item} />)}
    </div>
  );
};

export default ContainerDisplay;
