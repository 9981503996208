import useUser from "@/lib/useUser";
import Navbar from "./Navbar";

export interface LayoutProps {
  children: React.ReactNode;
  title?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children, title }) => {
  const { user } = useUser({
    redirectTo: "/signin",
  });

  return (
    <div className="min-h-full">
      <Navbar />

      {title && (
        <header className="bg-white shadow">
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">{title}</h1>
          </div>
        </header>
      )}
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          {user !== null && children}
        </div>
      </main>
    </div>
  );
};

export default Layout;
