import { useFetchJson } from "@/lib/fetchJson";
import React from "react";
import { PlayerInventory } from "@/lib/types";
import Loader from "@/components/Loader";
import InventoryDisplay from "@/components/Inventory/InventoryDisplay";


export interface InventoryProps {
  fullUuid: string;
}

const Inventory: React.FC<InventoryProps> = ({ fullUuid }) => {
  const { data: playerInventory, error, loading } = useFetchJson<PlayerInventory>([`/api/playerInventory?uuid=${fullUuid}`], [fullUuid]);

  if (loading) {
    return <Loader />;
  }

  if (playerInventory === null) {
    return (<div>Unable to fetch player inventory ({fullUuid})</div>);
  }

  return <InventoryDisplay playerInventory={playerInventory} />;
};

export default Inventory;
