import React, { useState } from "react";
import { PlayerInventory } from "@/lib/types";
import ItemIndicator from "@/components/Item/ItemIndicator";
import textureItems from "@/lib/textureItems";
import ContainerDisplay from "@/components/Container/ContainerDisplay";


export interface InventoryDisplayProps {
  playerInventory: Partial<PlayerInventory>;
}

const InventoryDisplay: React.FC<InventoryDisplayProps> = ({ playerInventory }) => {

  const [inventoryOpen, setInventoryOpen] = useState(false);
  const [enderchestOpen, setEnderchestOpen] = useState(false);

  return (
    <div className="flex py-1 flex-col">
      {
        playerInventory.inventory !== undefined && <>
          <div className="flex-initial ml-3 flex flex-row cursor-pointer" onClick={() => setInventoryOpen(!inventoryOpen)}>
            <ItemIndicator open={inventoryOpen} />
            <div className="flex-initial">
              <img className="h-8 w-8" src={textureItems['minecraft:crafting_table'].texture} />
            </div>
            <div className="flex-initial pl-1">
              <b>Inventory</b>
            </div>
          </div>
          {
            inventoryOpen && (
              <div className="flex-initial ml-6">
                <ContainerDisplay content={playerInventory.inventory} />
              </div>
            )
          }
        </>
      }
      {
        playerInventory.enderchest !== undefined && <>
          <div className="flex-initial ml-3 flex flex-row cursor-pointer" onClick={() => setEnderchestOpen(!enderchestOpen)}>
            <ItemIndicator open={enderchestOpen} />
            <div className="flex-initial">
              <img className="h-8 w-8" src={textureItems['minecraft:ender_chest'].texture} />
            </div>
            <div className="flex-initial pl-1">
              <b>Ender chest</b>
            </div>
          </div>
          {
            enderchestOpen && (
              <div className="flex-initial ml-6">
                <ContainerDisplay content={playerInventory.enderchest} />
              </div>
            )
          }
        </>
      }
    </div>
  );
};

export default InventoryDisplay;
